
import { AxiosResponse } from 'axios';
import api from '@/common/api';
import tools from '@/common/tools';
import inappTools from '@/common/inappTools';
import { native, storage } from '@/tools';
import promotionMixins from '../../../common/mixins/promotion';
import AppNav from '../../../components/AppNav.vue';

export default {
  name: 'PlanAllApply',
  components: {
    AppNav,
  },
  mixins: [promotionMixins],
  data() {
    return {
      selectedCourseType: 20,

      selectedTutorType: 'Global',
      //	minute

      tutorType: { C: 'Global', B: 'Native' },
      // t_type

      planDetail: [] as {},

      choicedPlan: [] as {},

      oneOption: false,

      prevPage: 'allPass',

      isPurchaseClicked: false,
    };
  },
  mounted() {
    this.goHome();

    if (this.$route.params.prevPage) {
      this.prevPage = this.$route.params.prevPage;
    } else if (this.$route.query.prevPage) {
      this.prevPage = this.$route.query.prevPage;
    }
    if (this.prevPage === 'allPass') {
      this.getAllPassDetail();
    }
    if (this.prevPage === 'Pr90daysMiddle') {
      this.getMiddlePromotionData();
    }
    if (this.prevPage === 'Pr90days') {
      this.getPromotionData();
    }
    if (this.prevPage === 'chattingPass') {
      this.getChatPromotionData();
    }
    if (this.prevPage !== 'allPass') {
      this.oneOption = true;
    }
    setTimeout(() => {
      const firstClick = document.getElementById('first-click');
      firstClick?.click();
    }, 600);
  },

  methods: {
    selectCourse(course: number | string): void {
      this.selectedCourseType = course;
    },

    selectTutor(tutor: number | string): void {
      this.selectedTutorType = tutor;
      this.selectedCourseType = 20;

      if (this.selectedCourseType === 20 && this.selectedTutorType === 'Native') {
        this.oneOption = true;
      } else {
        this.oneOption = false;
      }
    },

    async getAllPassDetail(): Promise<any> {
      this.planDetail = await api
        .get('/app/chargePlan/v2/allPassNew/detail', {
          channel: 4,
          mode: 'E',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.planDetail.plans.filter((data) => data.minute === 20 && data.t_type === 'C');
    },

    async getMiddlePromotionData(): Promise<any> {
      this.middlePromotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days_mid',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.middlePromotionData.plans.filter((data) => data.minute === 20);
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.promotionData.plans.filter((data) => data.minute === 20);
    },

    async getChatPromotionData(): Promise<any> {
      const { host } = window.location;
      console.log(host);
      this.ChatPromotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: 'chattingPass',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.ChatPromotionData.plans.filter((data) => data.minute === 20);
    },

    changeCourseArray(paramMinute, paramTutor) {
      if (this.prevPage === 'Pr90days') {
        this.choicedPlan = this.promotionData.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
      if (this.prevPage === 'Pr90daysMiddle') {
        this.choicedPlan = this.middlePromotionData.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
      if (this.prevPage === 'allPass') {
        this.choicedPlan = this.planDetail.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = `https://${document.location.host}/home/login`;
      }
    },

    purchasePlan(plan) {
      const copyLoggedInUser = storage.session.get('loggedInUser');
      localStorage.setItem('session.goodsForOrder', JSON.stringify(plan));
      if (copyLoggedInUser) {
        const {
          lms: { s_level: userLevel },
          goods: { show2022_90Days: userHasNoTicket },
        } = copyLoggedInUser;
        console.log('purchasPlan');
        console.log(this.prevPage);
        console.log(userLevel);
        console.log(userHasNoTicket);
        if (!userHasNoTicket && (this.prevPage === 'Pr90days' || this.prevPage === 'Pr90daysMiddle')) {
          this.puchaseErrorType = 'hasTicket';
          this.purchaseErrorMessage = '이 상품은 현재 수업 진행중인 수강권이 없는 회원을 위한 상품입니다.';
          this.openPurchaseModal();
        } else if (this.prevPage === 'Pr90days' && Number(userLevel) > 4) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>입문·초급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 이상입니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else if (this.prevPage === 'Pr90daysMiddle' && Number(userLevel) !== 7) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>중급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 아닙니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else {
          // eslint-disable-next-line no-useless-concat
          this.goPurchasePage();
        }
      } else {
        this.pushToLogin();
      }
    },
    goPurchasePage() {
      let url = '';
      if (inappTools.isInApp()) {
        url = `https://${document.location.host}/app/home/order`;
      } else {
        url = `https://${document.location.host}/home/order?&ptype=Kr&prevPage=${this.prevPage}`;
      }
      this.isPurchaseClicked = false;
      window.location.href = url;
    },
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2/classApply`;
      }
      if (inappTools.isInApp() && this.prevPage === 'chattingPass') {
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain');
        native.close();
      }
    },
    openPurchaseModal(): void {
      this.isPurchaseClicked = true;
    },
    closePurchaseModal(): void {
      localStorage.removeItem('session.goodsForOrder');
      this.isPurchaseClicked = false;
    },
  },
  computed: {
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },
    appBottom() {
      if (inappTools.isInApp()) {
        return '100px';
      }
      return '0px';
    },
    getTutorType() {
      return (tType) => (tType === 'C' ? this.tutorType.C : this.tutorType.B);
    },
    appView() {
      return inappTools.isInApp();
    },
  },
};
