<template>
  <!-- app nav -->
  <AppNav v-if="appView">수강 신청</AppNav>
  <section id="tutoringContent" :style="{ 'padding-top': appPadding }">
    <div class="pass-option">
      <div class="app_desktop" v-if="this.prevPage === 'Pr90daysMiddle'">
        <img
          src="https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_top_mid.png"
          class="banner_img"
          st
        />
      </div>
      <div class="app_desktop" v-if="this.prevPage === 'Pr90days'">
        <img
          src="https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_mind_top_start.png"
          class="banner_img"
        />
      </div>
      <div class="app_desktop" v-if="this.prevPage === 'chattingPass'">
        <img src="https://res.tutoring.co.kr/res/images/img/plans/renew2023/img_chat_top.png" class="banner_img" />
      </div>
      <div
        v-if="isPurchaseClicked"
        class="common-modal common-modal--dimmed"
        id="modalCertificateClose"
        style="display: block"
      >
        <div class="common-modal__modal modal--purchase" v-click-outside="closePurchaseModal">
          <div class="common-modal__content">
            <div class="common-modal__description" v-html="purchaseErrorMessage"></div>
            <div class="common-modal__description--small">
              {{ purchaseErrorSubMessage }}
            </div>
          </div>

          <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'level'">
            <button type="button" class="common-modal__button" @click="closePurchaseModal">취소</button>
            <button type="button" class="common-modal__button" @click="goPurchasePage">확인</button>
          </div>
          <div class="common-modal__buttonWrap" v-if="puchaseErrorType === 'hasTicket'">
            <button type="button" class="common-modal__button" @click="closePurchaseModal">확인</button>
          </div>
        </div>
      </div>
      <div class="pass-option__wrapper">
        <!-- info -->
        <div class="pass-option__info" v-if="this.prevPage === 'allPass'">
          <h2 class="pass-option__title">수강권 정보</h2>
          <div>
            <div class="info__thumb">
              <img
                src="//res.tutoring.co.kr/res/images/img/plans/img_thumb_plan_365.png"
                alt="365올패스 썸네일"
                width="80"
              />
            </div>
            <div class="info__detail">
              <h3 class="info__title">
                365올패스
                <span class="info__tag info__tag--fill-danger">업계최저가</span>
              </h3>
              <p class="info__desc">토픽과 스케줄을 나에게 맞춰 자유 선택</p>
            </div>
          </div>
        </div>

        <!-- // info -->
        <div class="group">
          <!-- 튜터 타입 -->
          <div class="pass-option__type" id="tutorType">
            <h2 class="pass-option__title">튜터 타입</h2>

            <div v-if="this.prevPage === 'chattingPass'">
              <div class="text">
                <div class="circle">
                  <span>G</span>
                </div>
                Global 튜터
              </div>
            </div>
            <div class="pass-option__tab" v-if="this.prevPage !== 'chattingPass'">
              <button
                type="button"
                id="first-click"
                class="tab__item"
                :class="{ 'tab__item tab__item--active': selectedTutorType === tutorType.C }"
                @click="selectTutor(tutorType.C), changeCourseArray(selectedCourseType, tutorType.C)"
              >
                <span class="global-badge">G</span>
                Global 튜터
              </button>

              <button
                type="button"
                class="tab__item"
                :class="{ 'tab__item tab__item--active': selectedTutorType === tutorType.B }"
                @click="selectTutor(tutorType.B), changeCourseArray(selectedCourseType, tutorType.B)"
              >
                <span class="native-badge">N</span>
                Native 튜터
              </button>
            </div>
            <div v-show="selectedTutorType === tutorType.C" class="type__box pass-option__tutor type__box--left">
              <img
                src="//res.tutoring.co.kr/res/images/img/plans/tutors_g.png"
                alt=""
                height="30"
                class="tutor__image"
              />
              <p class="tutor__desc">
                입문부터 초급, 중급인 학생에게 추천해요.
                <br />
                영어 사용국 튜터와 학습할 수 있어요.
              </p>
            </div>
            <div v-show="selectedTutorType === tutorType.B" class="type__box pass-option__tutor type__box--right">
              <img
                src="//res.tutoring.co.kr/res/images/img/plans/tutors_n.png"
                alt=""
                height="30"
                class="tutor__image"
              />
              <p class="tutor__desc">
                중급 이상의 학생에게 추천드려요.
                <br />
                영어가 모국어인 원어민 튜터와 학습해요.
              </p>
            </div>
          </div>
          <!-- // 튜터 타입 -->

          <!-- 수업 타입-->
          <div
            class="pass-option__type"
            id="classType"
            v-if="
              this.prevPage !== 'chattingPass' && this.prevPage !== 'Pr90days' && this.prevPage !== 'Pr90daysMiddle'
            "
          >
            <h2 class="pass-option__title">수업 타입</h2>
            <div class="pass-option__tab">
              <button
                type="button"
                class="tab__item"
                :class="{
                  'tab__item tab__item--active': selectedCourseType === 20,
                  oneOption: oneOption,
                }"
                @click="selectCourse(20), changeCourseArray(20, selectedTutorType)"
                data-class="20"
              >
                20분 수업
              </button>
              <button
                type="button"
                class="tab__item"
                :class="{ 'tab__item tab__item--active': selectedCourseType === 10 }"
                @click="selectCourse(10), changeCourseArray(10, selectedTutorType)"
                data-class="10"
                v-show="selectedTutorType === tutorType.C && this.prevPage === 'allPass'"
              >
                10분 수업
              </button>
            </div>
          </div>
          <!-- // 수업 타입-->
        </div>

        <div class="group">
          <!-- 요금제 선택 -->
          <div class="pass-option__order">
            <h2 class="pass-option__title">요금제 선택</h2>

            <a
              style="cursor: pointer"
              @click="purchasePlan(plan)"
              class="goToPay subscribe_plan"
              v-for="(plan, index) in choicedPlan"
              :key="index"
            >
              <div class="order__box">
                <div class="order__info">
                  <div class="order__chips">
                    <div class="order__chip" v-if="plan.isSubscribePlan === 1">구독기간별 추가 할인 혜택</div>
                    <div
                      class="order__chip"
                      v-if="
                        (this.prevPage === 'allPass' && plan.lesson_cnt === 96) ||
                        (this.prevPage === 'allPass' &&
                          plan.t_type === 'C' &&
                          plan.minute === 20 &&
                          (plan.lesson_cnt === 48 || plan.lesson_cnt === 114 || plan.lesson_cnt === 144)) ||
                        ((this.prevPage === 'Pr90daysMiddle' || this.prevPage === 'Pr90days') &&
                          plan.t_type === 'C' &&
                          plan.month_cnt === 6 &&
                          plan.lesson_cnt === 90) ||
                        (this.prevPage === 'chattingPass' && plan.lesson_cnt === 48)
                      "
                    >
                      추천Pick
                    </div>
                    <div class="order__chip--red" v-if="this.prevPage === 'allPass' && plan.isSubscribePlan !== 1">
                      업계최저가
                    </div>
                    <span class="order__chip--red" v-if="plan.isSubscribePlan !== 1">
                      <span>{{ plan.discount }}</span>
                      <span>%</span>
                    </span>
                  </div>
                  <h3 class="order__title">
                    {{ plan.lesson_cnt }}회
                    <div v-if="plan.isSubscribePlan === 1">&nbsp;정기권</div>
                  </h3>
                  <div v-if="this.prevPage === 'allPass'" class="order__detail">
                    {{ getTutorType(plan.t_type) }}
                    <i aria-hidden="true" class="dot"></i>
                    {{ plan.minute }}분
                    <i aria-hidden="true" class="dot"></i>
                    주 {{ plan.lessonPerWeek }}회
                    <div class="month" v-if="plan.isSubscribePlan !== 1">
                      <i aria-hidden="true" class="dot"></i>
                      {{ plan.month_cnt }}개월
                    </div>
                    <!-- 정기구독 들어가야 함 -->
                  </div>
                  <div v-if="this.prevPage !== 'allPass'" class="order__detail">
                    <div v-if="this.prevPage === 'Pr90daysMiddle'">중급</div>
                    <div v-if="this.prevPage === 'Pr90days'">초보</div>
                    <i v-if="this.prevPage !== 'chattingPass'" aria-hidden="true" class="dot"></i>
                    {{ getTutorType(plan.t_type) }}
                    <i aria-hidden="true" class="dot"></i>
                    {{ plan.minute }}분
                    <i aria-hidden="true" class="dot"></i>
                    <span v-if="this.prevPage !== 'chattingPass'">{{ plan.title.split(' ')[3] }}</span>
                    <span v-if="this.prevPage === 'chattingPass'">{{ plan.title.split(' ')[1] }}</span>
                    <i v-if="this.prevPage === 'chattingPass'" aria-hidden="true" class="dot"></i>
                    <span v-if="this.prevPage === 'chattingPass'">회화 {{ plan.title.split(' ')[5] }}</span>
                  </div>
                </div>
                <div class="order__price" v-if="plan.isSubscribePlan === 1" style="margin-top: 0">
                  <div class="price__sale">월 {{ priceComma(plan.moneyPerMonth) }}원</div>
                </div>
                <div class="order__price" v-if="plan.isSubscribePlan !== 1">
                  <div class="origin-price">정가 {{ priceComma(plan.additionalInfo.moneyInfo.planMoney) }}원</div>
                  <div class="discount-price">{{ priceComma(plan.money) }}원</div>
                  <div class="price__sale">월 {{ priceComma(plan.moneyPerMonth) }}원</div>
                </div>
              </div>
            </a>
          </div>
          <!-- // 요금제 선택 -->
        </div>
      </div>
      <!-- <div class="sub-content__banner">
        <a href="#" class="banner__link banner__link--refund btnDownloadApp"></a>
      </div> -->
    </div>
  </section>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import api from '@/common/api';
import tools from '@/common/tools';
import inappTools from '@/common/inappTools';
import { native, storage } from '@/tools';
import promotionMixins from '../../../common/mixins/promotion';
import AppNav from '../../../components/AppNav.vue';

export default {
  name: 'PlanAllApply',
  components: {
    AppNav,
  },
  mixins: [promotionMixins],
  data() {
    return {
      selectedCourseType: 20,

      selectedTutorType: 'Global',
      //	minute

      tutorType: { C: 'Global', B: 'Native' },
      // t_type

      planDetail: [] as {},

      choicedPlan: [] as {},

      oneOption: false,

      prevPage: 'allPass',

      isPurchaseClicked: false,
    };
  },
  mounted() {
    this.goHome();

    if (this.$route.params.prevPage) {
      this.prevPage = this.$route.params.prevPage;
    } else if (this.$route.query.prevPage) {
      this.prevPage = this.$route.query.prevPage;
    }
    if (this.prevPage === 'allPass') {
      this.getAllPassDetail();
    }
    if (this.prevPage === 'Pr90daysMiddle') {
      this.getMiddlePromotionData();
    }
    if (this.prevPage === 'Pr90days') {
      this.getPromotionData();
    }
    if (this.prevPage === 'chattingPass') {
      this.getChatPromotionData();
    }
    if (this.prevPage !== 'allPass') {
      this.oneOption = true;
    }
    setTimeout(() => {
      const firstClick = document.getElementById('first-click');
      firstClick?.click();
    }, 600);
  },

  methods: {
    selectCourse(course: number | string): void {
      this.selectedCourseType = course;
    },

    selectTutor(tutor: number | string): void {
      this.selectedTutorType = tutor;
      this.selectedCourseType = 20;

      if (this.selectedCourseType === 20 && this.selectedTutorType === 'Native') {
        this.oneOption = true;
      } else {
        this.oneOption = false;
      }
    },

    async getAllPassDetail(): Promise<any> {
      this.planDetail = await api
        .get('/app/chargePlan/v2/allPassNew/detail', {
          channel: 4,
          mode: 'E',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.planDetail.plans.filter((data) => data.minute === 20 && data.t_type === 'C');
    },

    async getMiddlePromotionData(): Promise<any> {
      this.middlePromotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days_mid',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.middlePromotionData.plans.filter((data) => data.minute === 20);
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: '2022_90days',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.promotionData.plans.filter((data) => data.minute === 20);
    },

    async getChatPromotionData(): Promise<any> {
      const { host } = window.location;
      console.log(host);
      this.ChatPromotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: 'chattingPass',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedPlan = this.ChatPromotionData.plans.filter((data) => data.minute === 20);
    },

    changeCourseArray(paramMinute, paramTutor) {
      if (this.prevPage === 'Pr90days') {
        this.choicedPlan = this.promotionData.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
      if (this.prevPage === 'Pr90daysMiddle') {
        this.choicedPlan = this.middlePromotionData.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
      if (this.prevPage === 'allPass') {
        this.choicedPlan = this.planDetail.plans.filter(
          (data) => data.minute === paramMinute && data.t_type === (paramTutor === 'Global' ? 'C' : 'B'),
        );
      }
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = `https://${document.location.host}/home/login`;
      }
    },

    purchasePlan(plan) {
      const copyLoggedInUser = storage.session.get('loggedInUser');
      localStorage.setItem('session.goodsForOrder', JSON.stringify(plan));
      if (copyLoggedInUser) {
        const {
          lms: { s_level: userLevel },
          goods: { show2022_90Days: userHasNoTicket },
        } = copyLoggedInUser;
        console.log('purchasPlan');
        console.log(this.prevPage);
        console.log(userLevel);
        console.log(userHasNoTicket);
        if (!userHasNoTicket && (this.prevPage === 'Pr90days' || this.prevPage === 'Pr90daysMiddle')) {
          this.puchaseErrorType = 'hasTicket';
          this.purchaseErrorMessage = '이 상품은 현재 수업 진행중인 수강권이 없는 회원을 위한 상품입니다.';
          this.openPurchaseModal();
        } else if (this.prevPage === 'Pr90days' && Number(userLevel) > 4) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>입문·초급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 이상입니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else if (this.prevPage === 'Pr90daysMiddle' && Number(userLevel) !== 7) {
          this.puchaseErrorType = 'level';
          this.purchaseErrorMessage = `
						이 상품은 <b>중급 레벨</b>을 위한 상품이며,<br />
						현재 회원님은 <b>중급 레벨</b> 아닙니다.<br />
						그럼에도 불구하고, 해당 상품 구매를 원하신다면<br />
						<b>‘확인’</b> 버튼을 눌러주세요.`;
          this.purchaseErrorSubMessage = `수강 관련하여 학습 상담팀에서 유선 연락을 드릴 예정입니다.`;
          this.openPurchaseModal();
        } else {
          // eslint-disable-next-line no-useless-concat
          this.goPurchasePage();
        }
      } else {
        this.pushToLogin();
      }
    },
    goPurchasePage() {
      let url = '';
      if (inappTools.isInApp()) {
        url = `https://${document.location.host}/app/home/order`;
      } else {
        url = `https://${document.location.host}/home/order?&ptype=Kr&prevPage=${this.prevPage}`;
      }
      this.isPurchaseClicked = false;
      window.location.href = url;
    },
    goHome() {
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2/classApply`;
      }
      if (inappTools.isInApp() && this.prevPage === 'chattingPass') {
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain');
        native.close();
      }
    },
    openPurchaseModal(): void {
      this.isPurchaseClicked = true;
    },
    closePurchaseModal(): void {
      localStorage.removeItem('session.goodsForOrder');
      this.isPurchaseClicked = false;
    },
  },
  computed: {
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },
    appBottom() {
      if (inappTools.isInApp()) {
        return '100px';
      }
      return '0px';
    },
    getTutorType() {
      return (tType) => (tType === 'C' ? this.tutorType.C : this.tutorType.B);
    },
    appView() {
      return inappTools.isInApp();
    },
  },
};
</script>

<style>
.pass-option__summary--active {
  color: rgb(51, 51, 51);
}
.banner_img {
  width: 100%;
}
.circle {
  display: flex;
  width: 15px;
  height: 15px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--height-m, 40px);
  background: var(--tutor-type-global, #020378);
}
.circle span {
  color: var(--opacity-white, #fff);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px; /* 100% */
}
.text {
  color: var(--opacity-black, #121214);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: start;
  margin-bottom: 16px;
}
.app_desktop {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
